<template>
  <page :title="`New ${Naming.Checklist}`" icon="clipboard-list">
    <template #tools>
      <div class="buttons">
        <submit-button
          v-bind="{ working }"
          @submit="saveChecklist"
          class="is-success is-rounded has-text-weight-bold"
          left-icon="check"
        >
          Create {{ Naming.Checklist }}
        </submit-button>
      </div>
    </template>

    <template #default>
      <div class="grid gap-1 mb-3">
        <!-- Information -->
        <div class="box is-marginless">
          <h2 class="box-title">{{ Naming.Checklist }} Information</h2>
          <div class="grid has-6-columns gap-1">
            <!-- Name -->
            <text-input
              class="is-2-columns"
              :error="$root.errors.name"
              required
              :value="checklist.name"
              @input="name"
              :description="`Give your ${Naming.Checklist.toLowerCase()} a descriptive name.`"
            >
              Name
            </text-input>

            <!-- Frequency -->
            <article class="is-2-columns">
              <formatted-text-input
                :error="$root.errors.frequency"
                :value="checklist.frequency"
                :options="{
                  numeral: true,
                  numeralDecimalScale: 0,
                  numeralThousandsGroupStyle: 'none',
                }"
                @input="frequency"
                :description="`How often will this ${Naming.Checklist.toLowerCase()} need to be performed?`"
              >
                Frequency
                <static-button slot="right">
                  <template v-if="checklist.frequency < 1"
                    >(no repeat instructions)</template
                  >
                  <template v-else>days</template>
                </static-button>
              </formatted-text-input>
            </article>

            <!-- Pass Percentage -->
            <formatted-text-input
              class="is-2-columns"
              required
              :options="{
                numeral: true,
                numeralDecimalScale: 0,
                numeralThousandsGroupStyle: 'none',
              }"
              :error="$root.errors.pass_percentage"
              :value="checklist.pass_percentage"
              @input="passPercentage"
              hint="Default is 80%"
              :description="`Set the minimum pass rate for this ${Naming.Checklist.toLowerCase()}.`"
            >
              Pass Percentage
              <static-button slot="right">%</static-button>
            </formatted-text-input>

            <!-- Toggle Requires a Signature -->
            <switch-input
              class="is-2-columns"
              :description="`When required, ${Naming.Checklists.toLowerCase()} of this type will require the ${Naming.Technician.toLowerCase()} to provide their signature before closing it off.`"
              classes="is-large"
              :value="checklist.requires_signature"
              @input="toggleSignatureRequirement">
              Requires a {{ Naming.Technician }} Signature
            </switch-input>

            <switch-input
              class="is-2-columns"
              description="When required, checklists of this type will require an approval signature before closing it off."
              classes="is-large"
              :value="checklist.requires_approver_signature"
              @input="toggleApproverSignatureRequirement">
              Requires an Approval Signature
            </switch-input>

            <!-- Toggle report images -->
            <switch-input
              class="is-2-columns"
              :description="`${Naming.Inspection} report evidence images inline with answer instead of at end of report.`"
              :hint="`Evidence images are displayed at the end of the ${Naming.Inspection.toLowerCase()} report by default. Selecting this option will move the images inline with the ${Naming.Check.toLowerCase()} answer.`"
              classes="is-large"
              :value="checklist.inspection_report_images_inline"
              @input="toggleInspectionReportImages">
              {{ Naming.Inspection }} Report Evidence Images Inline
            </switch-input>

          </div>
        </div>

        <!-- Checklists -->
        <div class="box">
          <h2 class="box-title checks-box-title">
            {{ checklist.checks.length }}
            {{ Naming.Check | plural(checklist.checks.length) }}
            <span class="has-text-danger has-text-left">*</span>
          </h2>
          <new-checklist-select-checkpoints />
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import NewChecklistSelectCheckpoints from "./partials/NewChecklistSelectCheckpoints";

export default {
  components: {
    NewChecklistSelectCheckpoints,
  },

  data: () => ({
    working: false,
  }),

  computed: {
    ...mapState("checklist", ["checklist"]),
  },

  beforeMount() {
    this.$store.commit("checklist/clear");
  },

  destroyed() {
    this.$store.commit("checklist/clear");
  },

  methods: {
    ...mapMutations("checklist", [
      "addCheck",
      "name",
      "frequency",
      "passPercentage",
      "toggleUpdatesNextInspection",
      "toggleSignatureRequirement",
      "toggleApproverSignatureRequirement",
      "toggleInspectionReportImages",
      "setChecks",
    ]),

    saveChecklist() {
      this.working = true;
      if(this.checklist.checks.length > 0){
        this.$store
          .dispatch("checklist/store")
          .then((data) => {
            this.$toast.success(this.Convert(this.$lang.checklist.created));
            this.$router.back()
          })
          .catch((error) => {
            this.working = false;
            this.$whoops();
          });
      } else {
        this.working = false;
        this.$toast.warning(this.Convert('Please add at least one checkpoint to create this checklist.'))
      }
    },
  },
};
</script>
